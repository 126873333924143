import { useState } from 'react';
import styled from '@emotion/styled';
import { CustomButton } from '../../../components/_Atoms';
import MultiSelect from '../../../components/_Atoms/MultiSelect';
import SelectDateRangeUniqueField, {
  IDateRange
} from '../../../components/_Atoms/selectDateRangeUniqueField';
import { ENUM_DATE_OPTIONS } from '../../../constants/kanban.constants';

const FilterContainer = styled.div`
  padding: 24px;
  background: #fff;
  border: 1px solid #aeb6be;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  grid-column: 3 / 5;
  align-items: flex-end;
`;

interface LeadsFilterFormProps {
  onFilter: (filters: LeadsFilters) => void;
  subGroups: any;
  promotors: any;
}

export interface LeadsFilters {
  proposalStatus: number[];
  promoterGroup: number[];
  executive: number[];
  leadGenerationPeriod: number[];
  leadStatus: number[];
  dateRange: IDateRange;
  dateOption: number;
}

const LeadsFilterForm = ({
  onFilter,
  subGroups,
  promotors
}: LeadsFilterFormProps) => {
  const [filters, setFilters] = useState<LeadsFilters>({
    proposalStatus: [],
    promoterGroup: [],
    executive: [],
    leadGenerationPeriod: [],
    leadStatus: [],
    dateRange: {
      start: null,
      end: null
    },
    dateOption: -1
  });

  const statusOptions = [
    { label: 'Em Andamento', value: 1 },
    { label: 'Em Processamento', value: 2 },
    { label: 'Aprovada', value: 3 },
    { label: 'Reprovada', value: 4 },
    { label: 'Concluida', value: 5 },
    { label: 'Cancelado', value: 6 },
    { label: 'Assinada', value: 7 }
  ];

  const executives = promotors.map((item: any) => ({
    label: item.Nome,
    value: item.ID
  }));

  const leadStatusOptions = [
    { label: 'Quente', value: 'hot' },
    { label: 'Morno', value: 'warm' },
    { label: 'Frio', value: 'cold' },
    { label: 'Congelado', value: 'frozen' }
  ];

  const handleClear = () => {
    const defaultFilters = {
      proposalStatus: [],
      promoterGroup: [],
      executive: [],
      leadGenerationPeriod: [],
      leadStatus: [],
      dateRange: {
        start: null,
        end: null
      },
      dateOption: -1
    };
    setFilters(defaultFilters);
    onFilter(defaultFilters);
  };

  const handleFilter = () => {
    console.log(filters, 'filtros');
    onFilter(filters);
  };

  return (
    <FilterContainer>
      <MultiSelect
        inputStyle={{ width: '100%' }}
        options={statusOptions}
        selected={filters.proposalStatus}
        setSelected={(values) =>
          setFilters({ ...filters, proposalStatus: values })
        }
        placeholder="Selecione o status"
        label="Status da Proposta"
        filter
      />

      <MultiSelect
        inputStyle={{ width: '100%' }}
        options={subGroups}
        selected={filters.promoterGroup}
        setSelected={(values) =>
          setFilters({ ...filters, promoterGroup: values })
        }
        placeholder="Selecione o subgrupo"
        label="Subgrupo do Promotor"
        filter
      />

      <MultiSelect
        inputStyle={{ width: '100%' }}
        options={executives}
        selected={filters.executive}
        setSelected={(values) => setFilters({ ...filters, executive: values })}
        placeholder="Selecione o executivo"
        label="Executivo (a)"
        filter
      />

      <SelectDateRangeUniqueField
        date={filters.dateRange}
        setDate={(dateRange) => setFilters({ ...filters, dateRange })}
        value={filters.dateOption}
        setValue={(value) => setFilters({ ...filters, dateOption: value })}
        label="Data da geração dos leads"
        options={[
          ENUM_DATE_OPTIONS['Essa semana'],
          ENUM_DATE_OPTIONS['Semana passada'],
          ENUM_DATE_OPTIONS['Últimos 30 dias'],
          ENUM_DATE_OPTIONS['Mês atual'],
          ENUM_DATE_OPTIONS['Mês anterior'],
          ENUM_DATE_OPTIONS['Últimos 3 meses']
        ]}
      />

      {/* <MultiSelect
        inputStyle={{ width: '100%' }}
        options={periodOptions}
        selected={filters.leadGenerationPeriod}
        setSelected={(values) =>
          setFilters({ ...filters, leadGenerationPeriod: values })
        }
        placeholder="Selecione o período"
        label="Período da Geração dos Leads"
        filter
      /> */}

      <MultiSelect
        inputStyle={{ width: '100%' }}
        options={leadStatusOptions}
        selected={filters.leadStatus}
        setSelected={(values) => setFilters({ ...filters, leadStatus: values })}
        placeholder="Selecione o status"
        label="Status do Lead (Qualificação)"
        filter
      />

      <ButtonContainer>
        <CustomButton value="Limpar" variant="secondary" onClick={handleClear}>
          Limpar
        </CustomButton>
        <CustomButton onClick={handleFilter}>Filtrar</CustomButton>
      </ButtonContainer>
    </FilterContainer>
  );
};

export default LeadsFilterForm;
